import Home from "./pages/Home/Home";
import React from 'react';
import './App.css';


function App() {
  return (
    <div className="App">
      <Home></Home>
    </div>
  );
}

export default App;